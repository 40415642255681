import * as React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import "./storySubmissionNavigation.scss"

const StorySubmissionNavigation = (props) => {
    const data = useStaticQuery(graphql`
        {
            craft {
                menu: navigationNodes(navHandle: "storySubmissionSectionNavigation", level: 1, status: null) {
                    title
                    nodeUri
                    enabled
                    children {
                        id
                        title
                        nodeUri
                    }
                }
            }
        }
    `)

    const getUrl = (node) => {
        // Test if empty
        if (node.nodeUri) {
            // Test if absolute URL
            var r = new RegExp('^(?:[a-z]+:)?//', 'i');
            if (r.test(node.nodeUri)) {
                return node.nodeUri
            } else {
                return `/${node.nodeUri}`
            }
        } else {
            return '#'
        }
    }

    const getLink = (node) => {
        if (node.nodeUri === props.active) {
            return (
                <a className="active" role="presentation">{node.title}</a>
            )
        } else {
            return (
                <a href={getUrl(node)}>{node.title}</a>
            )
        }
    }

    const navWalker = (nodes, child = false) => {
        if (!nodes || nodes.length === 0) {
            return null
        }

        if (child) {
            return nodes.map(node => (
                <li key={node.id}>{getLink(node)}</li>
            ))
        } else {
            return nodes.map(node => (
                <>
                    {node.nodeUri === "" && node.enabled === true ? (
                        <h3>{node.title}</h3>
                    ):(
                        null
                    )}
                    <ul>{ navWalker(node.children, true) }</ul>
                </>
            ))
        }
    }

    return (
        <>
            {navWalker(data.craft.menu)}
        </>
    )
}

export default StorySubmissionNavigation